<template>
  <div>
    <a-upload name="iFile" :max-count="1" :headers="headers" :action="uploadAction" @change="handleChange">
      <a-button type="dashed" block class="medium-input">
        <PlusOutlined />
        上传文件
      </a-button>
    </a-upload>
  </div>
</template>
<script>
  import {
    defineComponent,
    ref
  } from "vue";
  import {
    message
  } from "ant-design-vue";
  import {
    PlusOutlined
  } from "@ant-design/icons-vue";
  import storage from "store";
  import * as Api from "./api";

  export default defineComponent({
    components: {
      PlusOutlined,
    },
    name: "CsFile",
    props: {
      modelValue: String,
    },
    emits: ["update:modelValue"],
    setup(props, context) {
      const fileChange = () => {
        context.emit("update:modelValue");
      };
      //上传文件
      const uploadAction = Api.upload;

      const handleChange = (info) => {
        if (info.file.status === "done") {
          if (info.file.response.status === 200) {
            context.emit(
              "update:modelValue",
              info.file.response.data.fileInfo.file_path
            );
            message.success(`${info.file.name} 上传成功`);
          } else {
            message.error(info.file.response.message);
          }
        } else if (info.file.status === "error") {
          message.error(`${info.file.name} 上传失败.`);
        }
      };

      const headers = ref({
        "Access-Token": storage.get("ACCESS_TOKEN"),
      });

      return {
        uploadAction,
        handleChange,
        fileChange,
        headers,
      };
    },
  });
</script>